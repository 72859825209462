
import http from '../axios'



// 收藏 
export const $collectAPI = params => {
    return http.post('/collect', params)
}


// 移除收藏
export const $removeCollectAPi = params => {
    return http.post('/collect/cancel', params)
}


// 收藏列表
export const $getCollectListAPI = params => {
    return http.post('/collect/list', params)
}

// 我关注的公司列表
export const $getMyCollectCompany = params => {
    return http.post('/follow/list', params)
}
// 移出公司
export const $removeCollectCompany = params => {
    return http.post('/shop/follow/cancel', params)
}

// 报价详情页的点赞接口
export const $zanApi = params => {
    return http.post('/essay/likes', params)
}