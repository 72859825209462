import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onActivated, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

export default {
  setup(__props) {

const isShow = ref(false);
const goTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

onBeforeRouteLeave((to, from, next) => {
  window.onscroll = null;
  next();
});

onActivated(() => {
  window.onscroll = function () {
    let res = document.body.scrollTop || document.documentElement.scrollTop;
    isShow.value = res < 400 ? false : true;
  };
});

return (_ctx, _cache) => {
  return (isShow.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: goTop,
        class: "goto"
      }, "Top"))
    : _createCommentVNode("", true)
}
}

}